import "../../styles/ChargementPage.css"

function ChargemementPage () {
    return (
        <div className="chargement">
            chargement
        </div>
    )
}

export default ChargemementPage